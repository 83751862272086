/* eslint-disable max-len */
import { ProgramsDataType } from '../Programs/ProgramsPage.type.js';
import setId from './utils/setId.js';

export const programsDummy: ProgramsDataType[] = [
    {
        title: 'Авторизация',
        lists: [
            {
                title: 'Цель:',
                rows: [
                    'обеспечение процессов регистрации пользователя на УЭТП praktis.ru (далее в некоторых случаях - система) и аккредитации (включая и проверку ЭП). В качестве пользователей могут выступать: юридическое лицо, индивидуальный предприниматель и физическое лицо.',
                    'Реализованы подсистемы, обеспечивающие регистрацию пользователей и их аккредитацию.',
                ],
            },
            {
                title: 'Информация об установке: дополнительной установки не требуется.',
                rows: [],
                listRows: [
                    'ПО является Saas решением и установка не требуется, распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.',
                    'Вы можете приобрести право использования программного модуля «Авторизация» на условиях неисключительной лицензии для размещения и использования на своем ресурсе или площадке.',
                    'По вопросам приобретения права использования исходного кода программного модуля Вы можете обратиться на info@praktis.ru.',
                ],
            },
        ],
        links: [
            {
                name: 'Руководство по использованию',
                url: '/static/pdf/module_auth_manual.pdf',
            },
        ],
    },
    {
        title: 'Торговый блок',
        lists: [
            {
                title: 'Основные цели:',
                rows: [
                    'организация и проведение эффективных электронных торгов в различных отраслях (в частности, в строительной отрасли с участием СРО строителей, проектировщиков и изыскателей России);',
                    'предоставление в режиме реального времени компаниям, зарегистрированным на ЭТП, удобного доступа к полной информации о тендерах, проводимых государственными и частными заказчиками.',
                ],
            },
            {
                title: 'Программный модуль включает в себя следующие процедуры:',
                listRows: [
                    'аукционы (открытые, закрытые);',
                    'конкурсы (открытые, с ограниченным участием, двухэтапные, закрытые);',
                    'запросы предложений;',
                    'запросы котировок;',
                    'предквалификационный отбор.',
                ],
                rows: [],
            },
            {
                title: 'Информация об установке: дополнительной установки не требуется.',
                listRows: [
                    'ПО является Saas решением и установка не требуется, распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.',
                    'Вы можете приобрести право использования программного модуля «Торговый блок» на условиях неисключительной лицензии для размещения и использования на своем ресурсе или площадке.',
                    'По вопросам приобретения права использования исходного кода программного модуля Вы можете обратиться на  info@praktis.ru.',
                ],
                rows: [],
            },
        ],
        links: [
            {
                name: 'Руководство по использованию',
                url: '/static/pdf/module_tender_manual.pdf',
            },
        ],
    },
    {
        title: 'Интеграция с внешними системами',
        lists: [
            {
                title: 'Обеспечивает:',
                rows: [
                    'прием запросов от смежных систем, обработку полученных запросов и предоставление ответов на запросы, а также передачу запросов в смежные системы и обработку полученных ответов.',
                ],
            },
            {
                title: 'Информация об установке: дополнительной установки не требуется.',
                listRows: [
                    'ПО является Saas решением и установка не требуется, распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.',
                    'Вы можете приобрести право использования программного модуля «Интеграция с внешними системами» на условиях неисключительной лицензии для размещения и использования на своем ресурсе или площадке.',
                    'По вопросам приобретения права использования исходного кода программного модуля Вы можете обратиться на  info@praktis.ru.',
                ],
                rows: [],
            },
        ],
        links: [
            {
                name: 'Руководство по использованию',
                url: `/static/pdf/module_oos_manual.pdf`,
            },
        ],
    },
    {
        title: 'Хранилище файлов',
        lists: [
            {
                title: 'Обеспечивает:',
                rows: [
                    'автоматизацию процессов загрузки, хранения, редактирования и выдачи пользователям различных файлов (информации, содержащейся в файлах), обеспечивая при этом информационную безопасность.',
                ],
            },
            {
                title: 'Информация об установке: дополнительной установки не требуется.',
                listRows: [
                    'ПО является Saas решением и установка не требуется, распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.',
                    'Вы можете приобрести право использования программного модуля «Хранилище файлов» на условиях неисключительной лицензии для размещения и использования на своем ресурсе или площадке.',
                    'По вопросам приобретения права использования исходного кода программного модуля Вы можете обратиться на  info@praktis.ru.',
                ],
                rows: [],
            },
        ],
        links: [
            {
                name: 'Руководство по использованию',
                url: '/static/pdf/module_store_manual.pdf',
            },
        ],
    },
    {
        title: 'Договоры',
        lists: [
            {
                title: 'Обеспечивает:',
                rows: [
                    'автоматизацию процессов заключения договоров между заказчиками и поставщиками (исполнителями), оказание юридического сопровождения договоров, контроль за исполнением договоров и обеспечение информационной безопасности.',
                ],
            },
            {
                title: 'Информация об установке: дополнительной установки не требуется.',
                listRows: [
                    'ПО является Saas решением и установка не требуется, распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.',
                    'Вы можете приобрести право использования программного модуля «Договоры» на условиях неисключительной лицензии для размещения и использования на своем ресурсе или площадке.',
                    'По вопросам приобретения права использования исходного кода программного модуля Вы можете обратиться на  info@praktis.ru.',
                ],
                rows: [],
            },
        ],
        links: [
            {
                name: 'Руководство по использованию',
                url: '/static/pdf/module_contract_manual.pdf',
            },
        ],
    },
    {
        title: 'Биллинг',
        lists: [
            {
                title: 'Цель:',
                rows: ['автоматизация расчётов с клиентами.'],
            },
            {
                title: 'Программный модуль обеспечивает:',
                listRows: [
                    'создание лицевого счёта;',
                    'работу с балансовым (сервисным) лицевым счётом;',
                    'работу с обеспечительным (тендерным) счётом;',
                    'работу с нераспознанными платежами;',
                    'ведение реестра исходных платежей;',
                    'выгрузку документов бухгалтеру.',
                ],
                rows: [],
            },
            {
                title: 'Информация об установке: дополнительной установки не требуется.',
                listRows: [
                    'ПО является Saas решением и установка не требуется, распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.',
                    'Вы можете приобрести право использования программного модуля «Биллинг» на условиях неисключительной лицензии для размещения и использования на своем ресурсе или площадке.',
                    'По вопросам приобретения права использования исходного кода программного модуля Вы можете обратиться на  info@praktis.ru.',
                ],
                rows: [],
            },
        ],
        links: [
            {
                name: 'Руководство по использованию',
                url: '/static/pdf/module_billing_manual.pdf',
            },
        ],
    },
    {
        title: 'Уведомления',
        lists: [
            {
                title: 'Автономный алгоритм-сервис, который отвечает за все уведомления на площадке. Сервис отслеживает все сценарии, которые происходят в тендерном блоке (смены этапов, запросы, технические работы, отмена извещений и т.д.), имеет свою базу данных со всеми существующими шаблонами уведомлений. Уведомления приходят на электронную почту, по sms и в телеграмм-бот, указанные в личном кабинете.',
                rows: [],
            },
            {
                title: 'Информация об установке: дополнительной установки не требуется.',
                listRows: [
                    'ПО является Saas решением и установка не требуется, распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.',
                    'Вы можете приобрести право использования программного модуля «Уведомления» на условиях неисключительной лицензии для размещения и использования на своем ресурсе или площадке.',
                    'По вопросам приобретения права использования исходного кода программного модуля Вы можете обратиться на  info@praktis.ru.',
                ],
                rows: [],
            },
        ],
        links: [
            {
                name: 'Руководство по использованию',
                url: '/static/pdf/module_notice_manual.pdf',
            },
        ],
    },
    {
        title: 'Аукционный зал',
        lists: [
            {
                title: 'Программный модуль обеспечивает автоматизацию проведения различного вида торгов в электронной форме, а именно:',
                listRows: [
                    'торги на понижение от установленной начальной максимальной цены (НМЦ);',
                    'торги на повышение от установленной НМЦ;',
                    'торги на понижение от лучшего предложения участника;',
                    'взаимодействие с другими программными модулями, в частности, с Программным модулем «Торговый блок».',
                ],
                rows: [],
            },
            {
                title: 'Информация об установке: дополнительной установки не требуется.',
                listRows: [
                    'ПО является Saas решением и установка не требуется, распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.',
                    'Вы можете приобрести право использования программного модуля «Аукционный зал» на условиях неисключительной лицензии для размещения и использования на своем ресурсе или площадке.',
                    'По вопросам приобретения права использования исходного кода программного модуля Вы можете обратиться на  info@praktis.ru.',
                ],
                rows: [],
            },
        ],
        links: [
            {
                name: 'Руководство по использованию',
                url: '/static/pdf/module_auction_manual.pdf',
            },
        ],
    },
    {
        title: 'CRM организации',
        lists: [
            {
                title: 'Цель:',
                rows: [
                    'обеспечение надёжного взаимодействия между организатором торгов – УЭТП praktis.ru и клиентами, путём применения различных методов, инструментов и технологий, обеспечивающих развитие системы, удержание и привлечение клиентов к работе на УЭТП.',
                ],
            },
            {
                title: 'Реализованы подсистемы:',
                listRows: [
                    'обеспечивающие внесение/редактирование профиля компаний (клиентов);',
                    'установление связей с организациями – клиентами площадки;',
                    'установлена ролевая система, обеспечивающая управление правами доступа и возможность добавления нового члена комиссии.',
                ],
                rows: [],
            },
            {
                title: 'Информация об установке: дополнительной установки не требуется.',
                listRows: [
                    'ПО является Saas решением и установка не требуется, распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.',
                    'Вы можете приобрести право использования программного модуля «CRM организации» на условиях неисключительной лицензии для размещения и использования на своем ресурсе или площадке.',
                    'По вопросам приобретения права использования исходного кода программного модуля Вы можете обратиться на  info@praktis.ru.',
                ],
                rows: [],
            },
        ],
        links: [
            {
                name: 'Руководство по использованию',
                url: '/static/pdf/module_crm_manual.pdf',
            },
        ],
    },
    {
        title: 'Электронная торговая площадка ЕСТП',
        lists: [
            {
                title: 'Цель:',
                rows: [
                    'Электронная площадка ECТП предназначена для осуществления закупок коммерческими заказчиками и заказчиками, работающими в рамках Федерального закона № 223-ФЗ.',
                ],
            },
            {
                title: 'Информация об установке: дополнительной установки не требуется.',
                rows: [],
                listRows: [
                    'ПО является Saas решением и установка не требуется, распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.',
                    'Вы можете приобрести право использования программного модуля «Авторизация» на условиях неисключительной лицензии для размещения и использования на своем ресурсе или площадке.',
                    'По вопросам приобретения права использования исходного кода программного модуля Вы можете обратиться на info@praktis.ru.',
                ],
            },
        ],
        links: [
            {
                name: 'Руководство по использованию',
                url: '/static/pdf/module_etp_manual.pdf',
            },
        ],
    },
].map((program) => ({
    ...program,
    lists: program.lists.map(setId),
    links: program.links.map(setId),
}));
